import React from "react";
import Rainbow from "./rainbow";
import Poppper from "./popper";

class SimpleDialogDemo extends React.Component {
  state = {
    open: false,
    anchorEl: null,
    relevant: true,
    privileged: true,
    confidential: true
  };

  handleClick = event => {
    const { currentTarget } = event;
    this.setState(state => ({
      anchorEl: currentTarget,
      open: !state.open
    }));
  };

  handleClickOpen = () => {
    this.setState({
      open: true
    });
  };

  handleClose = selection => {
    this.setState({
      relevant: selection.relevant,
      privileged: selection.privileged,
      confidential: selection.confidential,
      open: false
    });
  };

  render() {
    return (
      <div>
        <Rainbow
          onClick={this.handleClick}
          red={this.state.relevant}
          blue={this.state.privileged}
          green={this.state.confidential}
        />
        <Poppper
          relevant={this.state.relevant}
          privileged={this.state.privileged}
          confidential={this.state.confidential}
          handleClose={this.handleClose}
          anchorEl={this.state.anchorEl}
          open={this.state.open}
        />
      </div>
    );
  }
}

export default SimpleDialogDemo;
