import { getSelectionActions } from "./selectedRows/actions";
import { getHiddenColumnsActions } from "./columnsToHide/actions";

export function getTableActions(panelIdentifier) {
  return Object.assign(
    {},
    getSelectionActions(panelIdentifier),
    getHiddenColumnsActions(panelIdentifier)
  );
}
