import RedwoodTable from './Table'

export const RedwoodTableConfig = {
  settings: {
    layout: {
      config: {}
    }
  },
  routes: [{
    path: '/redwood-table',
    component: RedwoodTable
  }]
}
