import React from "react";
import { withStyles } from "@material-ui/core";
import ButtonBase from "@material-ui/core/ButtonBase";
import classNames from "classnames";

const styles = theme => ({
  rainbowContainer: {
    margin: "5px",
    height: 20,
    width: 48,
    borderRadius: "25%",
    left: 0,
    zIndex: 3,
    position: "relative"
  },
  rainbowItem: {
    height: "20px",
    width: "20px",
    borderRadius: "50%",
    position: "absolute"
  },
  emptyButton: {
    left: 0,
    backgroundColor: "lightgray"
  }
});

export default withStyles(styles)(props => (
  <ButtonBase
    onClick={props.onClick}
    className={classNames(props.classes.rainbowContainer, {
      [props.classes.emptyButton]: !props.red && !props.blue && !props.green
    })}
  >
    <div
      style={{
        display: props.red ? "initial" : "none",
        zIndex: 0,
        left: props.blue ? 0 : "12px",
        backgroundColor: "#D94E47"
      }}
      className={props.classes.rainbowItem}
    />
    <div
      style={{
        display: props.blue ? "initial" : "none",
        zIndex: 1,
        left: "12px",
        backgroundColor: "#106EBE"
      }}
      className={props.classes.rainbowItem}
    />
    <div
      style={{
        display: props.green ? "initial" : "none",
        zIndex: 2,
        left: "26px",
        backgroundColor: "#BAD252"
      }}
      className={props.classes.rainbowItem}
    />
  </ButtonBase>
));
