import 'babel-polyfill'
import 'typeface-muli'

import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { Router } from 'react-router-dom'
import { create } from 'jss'
import jssExtend from 'jss-extend'
import JssProvider from 'react-jss/lib/JssProvider'

import { FuseLayout, FuseTheme, FuseAuthorization } from 'modules/@fuse'
import { routes } from 'client/fuse-configs/fuseRoutesConfig'
import MainToolbar from 'client/app/MainToolbar'
import MainNavbarContent from 'client/app/MainNavbarContent'
import MainNavbarHeader from 'client/app/MainNavbarHeader'
import MainFooter from 'client/app/MainFooter'
import SettingsPanel from 'client/app/SettingsPanel'

import './react-table-defaults'
import history from './history'
import store from './store'
import registerServiceWorker from './registerServiceWorker'

import './client/styles/index.css'
import { createGenerateClassName, jssPreset } from '@material-ui/core/styles'

const jss = create({
  ...jssPreset(),
  plugins: [...jssPreset().plugins, jssExtend()]
})

jss.options.insertionPoint = document.getElementById('jss-insertion-point')
const generateClassName = createGenerateClassName()

ReactDOM.render(
  <JssProvider jss={jss} generateClassName={generateClassName}>
    <Provider store={store}>
      <Router history={history}>
        <FuseAuthorization routes={routes}>
          <FuseTheme>
            <FuseLayout
              routes={routes}
              toolbar={
                <MainToolbar />
              }
              navbarHeader={
                <MainNavbarHeader />
              }
              navbarContent={
                <MainNavbarContent />
              }
              footer={
                <MainFooter />
              }
              contentWrapper={
                <SettingsPanel />
              }
            />
          </FuseTheme>
        </FuseAuthorization>
      </Router>
    </Provider>
  </JssProvider>
  , document.getElementById('root'))

registerServiceWorker()
