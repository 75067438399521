import React from "react";
import ExpandRowButton from "./expandRowButton";

const getExpandRowCell = props => {
  if (props.header) {
    return <div />;
  } else {
    return getCell(props);
  }
};

const getCell = ({ dataKey, token, classes, expandRow, expanded }) => {
  return (
    <div
      style={{
        display       : "flex",
        justifyContent: "center"
      }}
    >
      <ExpandRowButton expanded={expanded} expandRow={expandRow} />
    </div>
  );
};

export default getExpandRowCell;
