import { getSET_HIDDEN_COLUMNS } from "./actionTypes";

export function getHiddenColumnsActions(panelIdentifier) {
  const SET_HIDDEN_COLUMNS = getSET_HIDDEN_COLUMNS(panelIdentifier);

  return {
    setHiddenColumns(columns) {
      return {
        type: SET_HIDDEN_COLUMNS,
        columns
      };
    }
  };
}
