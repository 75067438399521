import React from 'react'
import { Redirect } from 'react-router-dom'

import { FuseUtils } from 'modules/@fuse/index'

import { RedwoodTableConfig } from 'client/app/applications/redwood-table/TableConfig'

import { PapyrusTableConfig } from 'client/app/applications/papyrus-table/TableConfig'
import { PapyrusQuickSearchConfig } from 'client/app/applications/papyrus-search-quick/SearchQuickConfig'

const routeConfigs = [
  RedwoodTableConfig,
  PapyrusTableConfig,
  PapyrusQuickSearchConfig
]

export const routes = [
  ...FuseUtils.generateRoutesFromConfigs(routeConfigs),
  {
    path: '/',
    component: () => <Redirect to='/papyrus-table' />
  }
]
