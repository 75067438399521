import React, { Component } from "react";
import PropTypes from "prop-types";
// import { withStyles } from "@material-ui/core/styles";
import Table from "./table";
import { AutoSizer } from "react-virtualized";
import {
  // PropDefValueTypes,
  // resolvePropToken,
  resolvePropValue
} from "../model/ValueTypes";
import { ReservedTypeNames } from "../model/ReservedTypeNames";
// import Checkbox from "./cellComponents/checkBox/CheckBox";
import sort from "./SortingHelper";
// import { fillRequiredIfNeed } from "./themeUtils";
// import { createMuiTheme, MuiThemeProvider } from "@material-ui/core/styles";

export const styles = theme => ({});

class TableComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      order: "asc",
      orderBy: ""
    };
  }

  handleRequestSort = props => {
    const orderBy = props.name;
    let order = "desc";

    if (this.state.orderBy === orderBy && this.state.order === "desc") {
      order = "asc";
    }

    this.setState({ order, orderBy });
  };

  getEntities(columns) {
    const { entities } = this.props;
    var sorters = [
      { direction: this.state.order, columnName: this.state.orderBy }
    ];
    const sortedEntities = sort(entities, columns, sorters);
    return sortedEntities.map(rowDef => {
      var row = {};
      rowDef.props.forEach(rowDefType => {
        var type = rowDefType.type;
        let customisedValue;
        // TODO Fix type def.
        if (rowDefType.type === "CLIENT") {
          type = "PROJECT_CLIENT";
        } else if (rowDefType.type === "DATE") {
          type = "PROJECT_DATE";
        } else if (rowDefType.type === "OWNER") {
          type = "PROJECT_OWNER";
        } else if (rowDefType.type === "SIZE") {
          type = "PROJECT_SIZE";
          const col = columns.find(c => c.name === type || c.name === rowDefType.type);
          if (col && col.converter) {
            console.log(rowDefType);
            customisedValue = col.converter(rowDefType);
          }
        } else if (rowDefType.type === "ENTITY_ID") {
          type = "ID";
        }
        row[type] = customisedValue || String(resolvePropValue(rowDefType));
      });
      row.token = rowDef.token;
      return row;
    });
  }

  getHeaderTitle(prop) {
    return (
      (prop.queryAliases &&
        prop.queryAliases.en &&
        prop.queryAliases.en.tokens &&
        prop.queryAliases.en.tokens.length &&
        prop.queryAliases.en.tokens[0]) ||
      (prop.type && prop.type.toLowerCase()) ||
      " "
    );
  }

  getColumns() {
    const {
      typeDefinition,
      columnsToHide // ,
      // expandedRow,
      // panelIdentifier,
      // selectedRowsSelector
    } = this.props;
    var columns = [
      // {
      //   name: "checkbox",
      //   header: (
      //     <Checkbox
      //       header
      //       panelIdentifier={panelIdentifier}
      //       selectedRowsSelector={selectedRowsSelector}
      //       entities={this.props.entities}
      //     />
      //   ),
      //   cell: rowData => (
      //     <Checkbox
      //       token={rowData.token}
      //       panelIdentifier={panelIdentifier}
      //       selectedRowsSelector={selectedRowsSelector}
      //     />
      //   ),
      //   cellProps: { style: { paddingTop: 1, paddingRight: 0 } },
      //   width: 40
      // }
    ];

    columns = columns.concat(
      typeDefinition && typeDefinition.props.map((typeDefProp, i) => {
        var type = typeDefProp.type;
        var cellProps = {};
        var width;
        var converter;
        if (
          type === ReservedTypeNames.ICON ||
          type === ReservedTypeNames.ENTITY_RAINBOW
        ) {
          width = 30;
        }
        if (
          type === ReservedTypeNames.PROJECT_STAR ||
          type === ReservedTypeNames.ENTITY_STAR
        ) {
          cellProps.style = {
            paddingTop: 8,
            paddingRight: 0,
            paddingLeft: 0
          };
          width = 10;

          return { // TODO - Remove when star feature is available
            width,
            cellProps
          }
        }
        if (type === "PROJECT_SIZE") {
          // assume a SIZE contains a converter nested prop for rendering it
          // TODO: when we don't need to be explicit about PROJECT_SIZE, this can work for any size type
          const innerProp = typeDefProp.props && typeDefProp.props.length && typeDefProp.props[0];
          const isFunc = innerProp && (innerProp.valueType === 'FUNC' || innerProp.valueType === 4);
          if (isFunc) {
            resolvePropValue(innerProp); // create a function
            converter = innerProp.memo;
          }
        }
        return {
          name: typeDefProp.type,
          header: this.getHeaderTitle(typeDefProp),
          width,
          cellProps,
          valueType: typeDefProp.valueType,
          converter
        };
      })
    );
    columns = columns.filter(c =>
      columnsToHide ? !columnsToHide.includes(c.name) : true
    );
    // if (expandedRow) { // TODO - Replace when expand details feature is available
    //   columns.push({ name: "EXPANSION_BUTTON", width: 60 });
    // }
    return columns;
  }

  render() {
    const {
      entityDetailsSelector,
      fetchEntityDetails,
      highlightToken,
      resizable,
      onCellClick // ,
      // themeDef
    } = this.props;
    const {
      order,
      orderBy // ,
      // selectedRowIds
    } = this.state;
    const columns = this.getColumns();
    const entities = this.getEntities(columns);
    return (
      <AutoSizer>
        {({ width, height }) => (
          <Table
            onHeaderClick={this.handleRequestSort}
            // isCellSelected={(column, rowData) =>
            //   this.props.selectedRows.some(
            //     token => rowData && rowData.token === token
            //   )
            // }
            isCellHovered={(column, rowData, hoveredColumn, hoveredRowData) =>
              rowData.token && rowData.token === hoveredRowData.token
            }
            resizable={resizable}
            highlight={highlightToken}
            width={width}
            height={height - 60}
            orderDirection={order}
            orderBy={orderBy}
            entityDetailsSelector={entityDetailsSelector}
            fetchEntityDetails={fetchEntityDetails}
            includeHeaders={true}
            data={entities}
            onCellClick={onCellClick}
            expandedRow={this.props.expandedRow}
            fixedRowCount={1}
            columns={columns}
            style={{
              backgroundColor: "white",
              overflowY: "auto"
            }}
          />
        )}
      </AutoSizer>
    );
  }
}

TableComponent.propTypes = {
  panelIdentifier: PropTypes.string.isRequired,
  data: PropTypes.array,
  columns: PropTypes.array,
  width: PropTypes.number,
  height: PropTypes.number,
  maxHeight: PropTypes.number,
  pagination: PropTypes.object,
  fitHeightToRows: PropTypes.bool,
  fixedRowCount: PropTypes.number,
  fixedColumnCount: PropTypes.number,
  rowHeight: PropTypes.number,
  columnWidth: PropTypes.number,
  includeHeaders: PropTypes.bool,
  orderBy: PropTypes.string,
  orderDirection: PropTypes.string,
  onHeaderClick: PropTypes.func,
  onCellClick: PropTypes.func,
  isCellHovered: PropTypes.func,
  isCellSelected: PropTypes.func,
  classes: PropTypes.object,
  cellProps: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
  style: PropTypes.object,
  columnsToHide: PropTypes.array,
  entities: PropTypes.array
};

export default TableComponent;
// withStyles(styles, { withTheme: true })(TableComponent);
