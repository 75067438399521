var _regeneratorRuntime = require("/codebuild/output/src798268309/src/github.com/Anexsys/redwood-ui-components/node_modules/babel-preset-react-app/node_modules/@babel/runtime/regenerator");

!function (e, t) {
  for (var n in t) {
    e[n] = t[n];
  }
}(exports, function (e) {
  var t = {};

  function n(r) {
    if (t[r]) return t[r].exports;
    var o = t[r] = {
      i: r,
      l: !1,
      exports: {}
    };
    return e[r].call(o.exports, o, o.exports, n), o.l = !0, o.exports;
  }

  return n.m = e, n.c = t, n.d = function (e, t, r) {
    n.o(e, t) || Object.defineProperty(e, t, {
      enumerable: !0,
      get: r
    });
  }, n.r = function (e) {
    "undefined" != typeof Symbol && Symbol.toStringTag && Object.defineProperty(e, Symbol.toStringTag, {
      value: "Module"
    }), Object.defineProperty(e, "__esModule", {
      value: !0
    });
  }, n.t = function (e, t) {
    if (1 & t && (e = n(e)), 8 & t) return e;
    if (4 & t && "object" == typeof e && e && e.__esModule) return e;
    var r = Object.create(null);
    if (n.r(r), Object.defineProperty(r, "default", {
      enumerable: !0,
      value: e
    }), 2 & t && "string" != typeof e) for (var o in e) {
      n.d(r, o, function (t) {
        return e[t];
      }.bind(null, o));
    }
    return r;
  }, n.n = function (e) {
    var t = e && e.__esModule ? function () {
      return e.default;
    } : function () {
      return e;
    };
    return n.d(t, "a", t), t;
  }, n.o = function (e, t) {
    return Object.prototype.hasOwnProperty.call(e, t);
  }, n.p = "", n(n.s = 14);
}([function (e, t) {
  e.exports = require("react");
}, function (e, t) {
  e.exports = require("jspath");
}, function (e, t) {
  e.exports = require("prop-types");
}, function (e, t) {
  e.exports = require("react-redux");
}, function (e, t) {
  e.exports = require("redux");
}, function (e, t) {
  e.exports = require("react-virtualized-tree");
}, function (e, t) {
  e.exports = require("react-virtualized-tree/lib/renderers");
}, function (e, t) {
  e.exports = require("@material-ui/core/FilledInput");
}, function (e, t) {
  e.exports = require("@material-ui/core/styles/withStyles");
}, function (e, t) {
  e.exports = require("classnames");
}, function (e, t) {
  e.exports = require("@material-ui/core");
}, function (e, t) {
  e.exports = require("react-virtualized/styles.css");
}, function (e, t) {
  e.exports = require("react-virtualized-tree/lib/main.css");
}, function (e, t) {
  e.exports = require("material-icons/css/material-icons.css");
}, function (e, t, n) {
  "use strict";

  n.r(t);
  var r = n(0),
      o = n.n(r),
      i = n(2),
      a = n.n(i),
      c = n(1),
      s = n.n(c),
      l = n(3),
      u = n(4),
      d = n(5),
      p = n.n(d),
      f = n(6),
      h = n.n(f),
      v = n(7),
      y = n.n(v),
      m = n(8),
      g = n.n(m),
      b = n(9),
      E = n.n(b),
      w = n(10);
  n(11), n(12), n(13);

  function O(e, t, n, r, o, i, a) {
    try {
      var c = e[i](a),
          s = c.value;
    } catch (e) {
      return void n(e);
    }

    c.done ? t(s) : Promise.resolve(s).then(r, o);
  }

  var T = "DIRECTORY";

  function k() {
    var e;
    return e = _regeneratorRuntime.mark(function e(t, n) {
      var r, o;
      return _regeneratorRuntime.wrap(function (e) {
        for (;;) {
          switch (e.prev = e.next) {
            case 0:
              if (console.groupCollapsed("[DIR_SERVICE] Resolving Directories RPC via stream..."), n) {
                e.next = 4;
                break;
              }

              throw console.groupEnd(), new Error("No JWT supplied to ".concat(T, " service"));

            case 4:
              return e.prev = 4, r = [], o = function o(e, t, n, _o) {
                console.log("[DIR_SERVICE] Chunk Handler: ", n.def), r.push(n.def);
              }, e.next = 9, t.stream("resolvePropDef", {
                corrId: t.corrIdGen(),
                token: n,
                type: "PATH_TREE_ENTRY"
              }, o);

            case 9:
              return e.abrupt("return", r);

            case 12:
              throw e.prev = 12, e.t0 = e.catch(4), new Error(e.t0.message);

            case 15:
              return e.prev = 15, console.groupEnd(), e.finish(15);

            case 18:
            case "end":
              return e.stop();
          }
        }
      }, e, this, [[4, 12, 15, 18]]);
    }), (k = function k() {
      var t = this,
          n = arguments;
      return new Promise(function (r, o) {
        var i = e.apply(t, n);

        function a(e) {
          O(i, r, o, a, c, "next", e);
        }

        function c(e) {
          O(i, r, o, a, c, "throw", e);
        }

        a(void 0);
      });
    }).apply(this, arguments);
  }

  function P(e, t, n) {
    return t in e ? Object.defineProperty(e, t, {
      value: n,
      enumerable: !0,
      configurable: !0,
      writable: !0
    }) : e[t] = n, e;
  }

  function C(e, t) {
    if (!(e instanceof t)) throw new TypeError("Cannot call a class as a function");
  }

  function x(e, t) {
    for (var n = 0; n < t.length; n++) {
      var r = t[n];
      r.enumerable = r.enumerable || !1, r.configurable = !0, "value" in r && (r.writable = !0), Object.defineProperty(e, r.key, r);
    }
  }

  function j(e, t, n) {
    return t && x(e.prototype, t), n && x(e, n), e;
  }

  var S = "[APP] GET DIRECTORY DATA",
      N = "[APP] GET DIRECTORY DATA SUCCESS",
      D = "[APP] GET DIRECTORY DATA FAILURE",
      R = "/",
      _ = function () {
    function e(t) {
      if (C(this, e), I.set(this, {
        writable: !0,
        value: void 0
      }), A.set(this, {
        writable: !0,
        value: void 0
      }), !t) throw new Error("Tree must be instantiated with a root node");
      this.internalCount = 0, this.plantTree(t);
    }

    return j(e, [{
      key: "plantTree",
      value: function value(e) {
        e.id = 0, e.state = Object.assign({}, e.state, {
          expanded: !0
        }), this.cache = new Map(), this.cache.set(this.leafCount++, e);
      }
    }, {
      key: "insertNode",
      value: function value() {
        var e = arguments.length > 0 && void 0 !== arguments[0] ? arguments[0] : {};
        if (console.groupCollapsed("".concat(e.name)), !e) return !1;
        console.log("[INSERTING NODE]: ".concat(e.path, "/").concat(e.name));
        var t = this.findNodeByPath(e.path);

        if (t) {
          var n = this.leafCount++;
          console.log("[INSERTING NODE] Parent found: ".concat(t.name, " (").concat(t.path, ") - Attaching node as ID: ").concat(n)), e.id = n, this.cache.set(n, e), t.addChild(e);
        }

        return console.groupEnd(), e;
      }
    }, {
      key: "findNodeById",
      value: function value(e) {
        return e && "number" == typeof e && this.cache.get(e) || !1;
      }
    }, {
      key: "findNodeByPath",
      value: function value() {
        var e = arguments.length > 0 && void 0 !== arguments[0] ? arguments[0] : "",
            t = this.cache.get(0);
        return this.getNodeAt(e, t);
      }
    }, {
      key: "getNodeAt",
      value: function value(e, t) {
        if (!t) return console.log("[GET_NODE_AT] No node supplied"), !1;
        if (console.log("[GET_NODE_AT] Looking at child (".concat(t.fullPath, ")")), t && t.fullPath && t.fullPath === e) return console.log("[GET_NODE_AT] Node found: ".concat(e)), t;

        if (t.children) {
          for (var n = [], r = 0; r < t.children.length; r++) {
            var o = t.children[r].fullPath;
            ~e.toLowerCase().indexOf(o.toLowerCase()) && n.push(this.getNodeAt(e, t.children[r]));
          }

          var i = n.filter(function (e) {
            return !1 !== e;
          });
          if (i.length) return i.length > 1 && console.warn("Multiple upstream nodes found for path: ".concat(e)), console.log("[GET_NODE_AT] Upstream node:", i[0]), i[0];
        }

        return !1;
      }
    }, {
      key: "flatten",
      value: function value() {}
    }, {
      key: "leafCount",
      get: function get() {
        return this.internalCount;
      },
      set: function set(e) {
        return this.internalCount = e, this.internalCount;
      }
    }]), e;
  }(),
      I = new WeakMap(),
      A = new WeakMap(),
      H = function () {
    function e(t, n, r, o, i) {
      C(this, e), P(this, "id", void 0), P(this, "name", void 0), P(this, "path", void 0), P(this, "type", void 0), P(this, "valueType", void 0), M.set(this, {
        writable: !0,
        value: void 0
      }), L.set(this, {
        writable: !0,
        value: void 0
      }), q.set(this, {
        writable: !0,
        value: void 0
      }), this.name = t, this.path = n, this.fullPath = r, this.type = o, this.valueType = i, this.filterType = "treeNode", this.state = {
        expanded: !1,
        favorite: !1,
        deletable: !1
      };
    }

    return j(e, [{
      key: "addChild",
      value: function value(e) {
        this.children || (this.children = []), this.children.push(e);
      }
    }]), e;
  }(),
      M = new WeakMap(),
      L = new WeakMap(),
      q = new WeakMap();

  function F(e, t) {
    return function (n, r) {
      return console.log("fetchDirectories starting"), n({
        type: S
      }), function (e, t) {
        return k.apply(this, arguments);
      }(e, t).then(function (e) {
        console.log("[FETCH DIRECTORIES] Completed - Raw Directory Props:", e);

        var t = function () {
          var e,
              t = arguments.length > 0 && void 0 !== arguments[0] ? arguments[0] : [],
              n = arguments.length > 1 ? arguments[1] : void 0,
              r = t[0],
              o = !(!r || !r.value0 || "/" !== r.value0) && t.splice(0, 1);

          if (o) {
            var i = new H("/", "/", "/", o.type, o.valueType);
            e = new _(i);
          } else e = new _(new H("/", "/", "/", "PATH_TREE_ENTRY", "value0"));

          if (!(e || o || n)) throw new Error("No Tree supplied to translation function");
          e || o || !n || (e = n);
          if (!e) throw new Error("Something went wrong here - no tree");
          return console.groupCollapsed(), t.map(function (t) {
            var n = t.value0.split(R),
                r = n[n.length - 1],
                o = t.value0.substring(0, t.value0.lastIndexOf("/")) || "/",
                i = new H(r, o, t.value0, t.type, t.valueType);
            return e.insertNode(i);
          }), console.groupEnd(), e;
        }(e);

        return n(function (e) {
          return {
            type: N,
            tree: e
          };
        }(t)), t;
      }).catch(function (e) {
        return console.error(e.message), n({
          type: D,
          error: e
        }), e;
      });
    };
  }

  function G(e, t, n) {
    return t in e ? Object.defineProperty(e, t, {
      value: n,
      enumerable: !0,
      configurable: !0,
      writable: !0
    }) : e[t] = n, e;
  }

  var V = {
    loading: !0
  };

  function Y() {
    var e = arguments.length > 0 && void 0 !== arguments[0] ? arguments[0] : V,
        t = arguments.length > 1 ? arguments[1] : void 0;

    switch (t.type) {
      case S:
        return function (e, t) {
          return function (e) {
            for (var t = 1; t < arguments.length; t++) {
              var n = null != arguments[t] ? arguments[t] : {},
                  r = Object.keys(n);
              "function" == typeof Object.getOwnPropertySymbols && (r = r.concat(Object.getOwnPropertySymbols(n).filter(function (e) {
                return Object.getOwnPropertyDescriptor(n, e).enumerable;
              }))), r.forEach(function (t) {
                G(e, t, n[t]);
              });
            }

            return e;
          }({}, e, {
            loading: !0
          });
        }(e);

      case N:
        return function (e) {
          var t = arguments.length > 1 && void 0 !== arguments[1] ? arguments[1] : {};
          return Object.assign({}, e, {
            loading: !1,
            tree: t.tree
          });
        }(e, t);

      case D:
        return function (e) {
          var t = arguments.length > 1 && void 0 !== arguments[1] ? arguments[1] : {};
          return console.error(t.error), Object.assign({}, e, {
            loading: !1,
            error: t.error
          });
        }(e, t);

      default:
        return e;
    }
  }

  function W() {
    return (W = Object.assign || function (e) {
      for (var t = 1; t < arguments.length; t++) {
        var n = arguments[t];

        for (var r in n) {
          Object.prototype.hasOwnProperty.call(n, r) && (e[r] = n[r]);
        }
      }

      return e;
    }).apply(this, arguments);
  }

  function U(e, t) {
    if (null == e) return {};

    var n,
        r,
        o = function (e, t) {
      if (null == e) return {};
      var n,
          r,
          o = {},
          i = Object.keys(e);

      for (r = 0; r < i.length; r++) {
        n = i[r], t.indexOf(n) >= 0 || (o[n] = e[n]);
      }

      return o;
    }(e, t);

    if (Object.getOwnPropertySymbols) {
      var i = Object.getOwnPropertySymbols(e);

      for (r = 0; r < i.length; r++) {
        n = i[r], t.indexOf(n) >= 0 || Object.prototype.propertyIsEnumerable.call(e, n) && (o[n] = e[n]);
      }
    }

    return o;
  }

  function z(e, t, n, r, o, i, a) {
    try {
      var c = e[i](a),
          s = c.value;
    } catch (e) {
      return void n(e);
    }

    c.done ? t(s) : Promise.resolve(s).then(r, o);
  }

  function B(e) {
    return (B = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (e) {
      return typeof e;
    } : function (e) {
      return e && "function" == typeof Symbol && e.constructor === Symbol && e !== Symbol.prototype ? "symbol" : typeof e;
    })(e);
  }

  function K(e, t) {
    if (!(e instanceof t)) throw new TypeError("Cannot call a class as a function");
  }

  function J(e, t) {
    for (var n = 0; n < t.length; n++) {
      var r = t[n];
      r.enumerable = r.enumerable || !1, r.configurable = !0, "value" in r && (r.writable = !0), Object.defineProperty(e, r.key, r);
    }
  }

  function Q(e, t, n) {
    return t && J(e.prototype, t), n && J(e, n), e;
  }

  function X(e, t) {
    return !t || "object" !== B(t) && "function" != typeof t ? te(e) : t;
  }

  function Z(e) {
    return (Z = Object.setPrototypeOf ? Object.getPrototypeOf : function (e) {
      return e.__proto__ || Object.getPrototypeOf(e);
    })(e);
  }

  function $(e, t) {
    if ("function" != typeof t && null !== t) throw new TypeError("Super expression must either be null or a function");
    e.prototype = Object.create(t && t.prototype, {
      constructor: {
        value: e,
        writable: !0,
        configurable: !0
      }
    }), t && ee(e, t);
  }

  function ee(e, t) {
    return (ee = Object.setPrototypeOf || function (e, t) {
      return e.__proto__ = t, e;
    })(e, t);
  }

  function te(e) {
    if (void 0 === e) throw new ReferenceError("this hasn't been initialised - super() hasn't been called");
    return e;
  }

  function ne(e) {
    for (var t = 1; t < arguments.length; t++) {
      var n = null != arguments[t] ? arguments[t] : {},
          r = Object.keys(n);
      "function" == typeof Object.getOwnPropertySymbols && (r = r.concat(Object.getOwnPropertySymbols(n).filter(function (e) {
        return Object.getOwnPropertyDescriptor(n, e).enumerable;
      }))), r.forEach(function (t) {
        re(e, t, n[t]);
      });
    }

    return e;
  }

  function re(e, t, n) {
    return t in e ? Object.defineProperty(e, t, {
      value: n,
      enumerable: !0,
      configurable: !0,
      writable: !0
    }) : e[t] = n, e;
  }

  var oe = "directoryList",
      ie = h.a.Expandable,
      ae = function ae(e) {
    var t = e.node,
        n = e.children,
        r = e.onChange,
        i = t.state,
        a = (i = void 0 === i ? {} : i).selected,
        c = E()({
      "mi mi-check-box": a,
      "mi mi-check-box-outline-blank": !a
    });
    return o.a.createElement("span", null, o.a.createElement("i", {
      className: c,
      onClick: function onClick() {
        r({
          node: ne({}, t, {
            state: ne({}, t.state || {}, {
              selected: !a
            })
          }),
          type: "SELECT_OPTION"
        });
      }
    }), n);
  },
      ce = function (e) {
    function t(e) {
      var n;
      return K(this, t), (n = X(this, Z(t).call(this, e))).state = {
        editValue: n.props.node.name
      }, n.clickHandler = n.clickHandler.bind(te(te(n))), n.doneHandler = n.doneHandler.bind(te(te(n))), n.recordVal = n.recordVal.bind(te(te(n))), n;
    }

    return $(t, r["Component"]), Q(t, [{
      key: "clickHandler",
      value: function value() {
        var e = this.props.node,
            t = this.state.editValue || e.name;
        this.props.onChange({
          node: ne({}, e, {
            name: t,
            fullPath: "".concat(e.path, "/").concat(t),
            state: ne({}, e.state || {}, {
              editing: !e.state.editing
            })
          }),
          type: "EDIT_OPTION"
        });
      }
    }, {
      key: "doneHandler",
      value: function value() {
        var e = this;
        this.props.persistEdit(this.props.node, this.state.editValue).then(function (t) {
          return e.clickHandler();
        });
      }
    }, {
      key: "recordVal",
      value: function value(e) {
        this.setState({
          editValue: e.target.value
        });
      }
    }, {
      key: "render",
      value: function value() {
        var e = this,
            t = this.props.node,
            n = t.state,
            r = (n = void 0 === n ? {} : n).editing,
            i = t.name;
        return "/" === i ? o.a.createElement("span", null, i) : r ? o.a.createElement("span", null, o.a.createElement("i", {
          className: "mi mi-clear",
          onClick: this.clickHandler
        }), o.a.createElement("i", {
          className: "mi mi-done",
          onClick: this.doneHandler
        }), o.a.createElement(y.a, {
          disableUnderline: !0,
          value: this.state.editValue,
          onChange: this.recordVal,
          onKeyDown: function onKeyDown(t) {
            t && t.keyCode && 13 === t.keyCode && e.doneHandler();
          },
          inputProps: {
            "aria-label": "Edit Folder"
          },
          margin: "dense",
          classes: {
            inputMarginDense: this.props.classes.inputMarginDense
          }
        })) : o.a.createElement("span", null, o.a.createElement("i", {
          className: "mi mi-edit",
          onClick: this.clickHandler
        }), i);
      }
    }]), t;
  }(),
      se = function (e) {
    function t(e, n) {
      var r;
      if (K(this, t), !(r = X(this, Z(t).call(this, e, n))).context.hasOwnProperty("store")) throw new Error("No store passed to DirectoryList component via context");
      if (!r.context.hasOwnProperty("api")) throw new Error("No websocket passed to DirectoryList component via context");
      return r.store = r.context.store, r.api = r.context.api, r.store.injectReducer(oe, Y), r.state = {
        nodes: []
      }, r.dirTree = o.a.createRef(), r.handleChange = r.handleChange.bind(te(te(r))), r.nodeSelectionHandler = r.nodeSelectionHandler.bind(te(te(r))), r.nodeEditHandler = r.nodeEditHandler.bind(te(te(r))), r.persistEdit = r.persistEdit.bind(te(te(r))), r;
    }

    return $(t, r["Component"]), Q(t, [{
      key: "persistEdit",
      value: function () {
        var e,
            t = (e = _regeneratorRuntime.mark(function e(t, n) {
          var r, o;
          return _regeneratorRuntime.wrap(function (e) {
            for (;;) {
              switch (e.prev = e.next) {
                case 0:
                  return r = {
                    type: "PATH_TREE_ENTRY",
                    template: !1,
                    valueType: 9,
                    value9: {
                      pairs: re({}, t.fullPath, "".concat(t.path, "/").concat(n))
                    }
                  }, e.next = 3, this.api.rpc("runAction", {
                    token: this.props.token,
                    actionKey: "SET_PROP",
                    corrId: this.api.corrIdGen(),
                    args: r
                  });

                case 3:
                  return o = e.sent, e.abrupt("return", o);

                case 5:
                case "end":
                  return e.stop();
              }
            }
          }, e, this);
        }), function () {
          var t = this,
              n = arguments;
          return new Promise(function (r, o) {
            var i = e.apply(t, n);

            function a(e) {
              z(i, r, o, a, c, "next", e);
            }

            function c(e) {
              z(i, r, o, a, c, "throw", e);
            }

            a(void 0);
          });
        });
        return function (e, n) {
          return t.apply(this, arguments);
        };
      }()
    }, {
      key: "computeStyles",
      value: function value() {
        var e = arguments.length > 0 && void 0 !== arguments[0] ? arguments[0] : {};
        return e.marginLeft && (e.marginLeft = e.marginLeft / 2), e;
      }
    }, {
      key: "handleChange",
      value: function value(e) {
        this.setState({
          nodes: e
        });
      }
    }, {
      key: "selectNodes",
      value: function value(e, t) {
        var n = this;
        return e.map(function (e) {
          return ne({}, e, {
            children: e.children ? n.selectNodes(e.children, t) : [],
            state: ne({}, e.state, {
              selected: t
            })
          });
        });
      }
    }, {
      key: "nodeSelectionHandler",
      value: function value(e, t) {
        var n = this;
        return (!(arguments.length > 2 && void 0 !== arguments[2]) || arguments[2]) && (t.state.selected ? this.props.recordFilter(t.fullPath, "dir", "", "equals", t.fullPath) : this.props.removeFilter(t.fullPath), this.props.submitSearch(this.api, this.props.token)), e.map(function (e) {
          return e.id === t.id ? ne({}, t, {
            children: e.children ? n.selectNodes(e.children, t.state.selected) : []
          }) : e.children ? ne({}, e, {
            children: n.nodeSelectionHandler(e.children, t, !1)
          }) : e;
        });
      }
    }, {
      key: "nodeEditHandler",
      value: function value(e, t) {
        var n = this;
        return e.map(function (e) {
          return e.id === t.id ? t : e.children ? ne({}, e, {
            children: n.nodeEditHandler(e.children, t)
          }) : e;
        });
      }
    }, {
      key: "componentWillMount",
      value: function value() {
        var e = this,
            t = s()(".project.token[0]", this.props);
        this.props.resolveDirectories(this.api, t).then(function (t) {
          console.log("[DIRECTORY_LIST] resolveDIR success", t), e.setState({
            nodes: [t.cache.get(0)]
          });
        }).catch(function (e) {
          console.error(e.message);
        });
      }
    }, {
      key: "componentDidUpdate",
      value: function value(e) {
        if (e.hasResults && !this.props.hasResults) {
          var t = this.state.nodes[0];
          this.handleChange(this.nodeSelectionHandler(this.state.nodes, ne({}, t, {
            state: ne({}, t.state || {}, {
              selected: !1
            })
          }), !1));
        }
      }
    }, {
      key: "componentDidCatch",
      value: function value(e, t) {
        console.error(e, t), this.setState({
          error: e,
          errorFound: !0
        });
      }
    }, {
      key: "render",
      value: function value() {
        var e,
            t,
            n = this,
            r = this.props,
            i = r.error,
            a = r.directoriesLoading,
            c = this.state.errorFound || i ? (t = n.state.errorFound ? n.state.error : n.props.error, [o.a.createElement("pre", {
          key: "ErrorMessage"
        }, t && t.message), o.a.createElement("pre", {
          key: "ErrorStack"
        }, t && t.stack)]) : null,
            s = !c && a ? o.a.createElement("div", {
          className: "flex flex-1 flex-col items-center justify-center",
          style: {
            background: "#FAFAFA"
          }
        }, o.a.createElement(w.LinearProgress, {
          className: "w-xs",
          color: "secondary"
        })) : null;
        return c || s || o.a.createElement(o.a.Fragment, null, o.a.createElement(p.a, {
          ref: this.dirTree,
          nodes: this.state.nodes,
          onChange: this.handleChange,
          extensions: {
            updateTypeHandlers: (e = {}, re(e, "SELECT_OPTION", this.nodeSelectionHandler), re(e, "EDIT_OPTION", this.nodeEditHandler), e)
          }
        }, function (e) {
          var t = e.style,
              r = e.node,
              i = U(e, ["style", "node"]);
          return o.a.createElement("div", {
            style: n.computeStyles(t)
          }, o.a.createElement(ie, W({
            node: r
          }, i, {
            iconsClassNameMap: {
              expanded: "mi mi-folder-open",
              collapsed: "mi mi-folder",
              lastChild: "mi mi-insert-drive-file"
            }
          }), o.a.createElement(ae, W({
            node: r
          }, i), o.a.createElement(ce, W({
            node: r,
            classes: n.props.classes,
            persistEdit: n.persistEdit
          }, i)))));
        }));
      }
    }]), t;
  }();

  re(se, "contextTypes", {
    store: a.a.object,
    api: a.a.object
  });
  var le = g()(function (e) {
    return {
      inputMarginDense: {
        fontSize: "1em",
        padding: "1px"
      }
    };
  }, {
    withTheme: !0
  })(Object(l.connect)(function (e) {
    var t = null;

    try {
      t = s()(".activity.project.config.entityTemplate.props", e.globalAppData).map(function (e) {
        return e.type;
      });
    } catch (e) {
      console.warn("[DOCUMENT_LIST] Mapping Definition: ".concat(e.message));
    }

    return {
      projectTemplate: t,
      directoriesLoading: s()(".loading[0]", e[oe]),
      project: s()(".activity.project[0]", e.globalAppData)
    };
  }, function (e) {
    return Object(u.bindActionCreators)({
      resolveDirectories: F
    }, e);
  })(se));
  n.d(t, "DirectoryList", function () {
    return le;
  });
  t.default = le;
}]));