import { getselectionReducer } from "./selectedRows/reducer";
import { gethiddenColumnsReducer } from "./columnsToHide/reducer";
import { combineReducers } from "redux";

export function getTableReducers(panelIdentifier) {
  const selectionReducer = getselectionReducer(panelIdentifier);
  const hiddenColumnsReducer = gethiddenColumnsReducer(panelIdentifier);

  return combineReducers({
    selection    : selectionReducer,
    hiddenColumns: hiddenColumnsReducer
    // columnOrder : columnOrderReducer,
    // expandedRows: expandedRowsReducer,
    // columnWidths: columnWidthsReducer
  });
}
