import React from 'react'
import PropTypes from 'prop-types'
import Button from '@material-ui/core/Button'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import Dialog from '@material-ui/core/Dialog'
import FormGroup from '@material-ui/core/FormGroup'
import Checkbox from '@material-ui/core/Checkbox'
import FormControlLabel from '@material-ui/core/FormControlLabel'

class ConfirmationDialog extends React.Component {
  constructor (props) {
    super(props)
    var state = {}

    var columns = props.typeDefinition.props.map(p => p.type)
    columns.forEach(c => {
      state[c] = true
    })
    props.columnsToHide.forEach(c => {
      state[c] = false
    })

    this.state = state
  }

  handleCancel = () => {
    this.props.onCancel()
  };

  handleOk = () => {
    var columnsToHide = []
    Object.keys(this.state).forEach(key => {
      if (!this.state[key]) columnsToHide.push(key)
    })

    this.props.onClose(columnsToHide)
  };

  handleChange = name => event => {
    this.setState({ [name]: event.target.checked })
  };

  getHeaderTitle (prop) {
    return (
      (prop.queryAliases &&
        prop.queryAliases.en &&
        prop.queryAliases.en.tokens &&
        prop.queryAliases.en.tokens.length &&
        prop.queryAliases.en.tokens[0]) ||
      (prop.type && prop.type.toLowerCase()) ||
      ' '
    )
  }

  render () {
    const { open, classes, typeDefinition } = this.props
    return (
      <Dialog
        disableBackdropClick
        disableEscapeKeyDown
        maxWidth='xs'
        onEntering={this.handleEntering}
        aria-labelledby='confirmation-dialog-title'
        open={open}
        classes={classes}
      >
        <DialogTitle id='confirmation-dialog-title'>Columns</DialogTitle>

        <DialogContent>
          <FormGroup>
            {typeDefinition.props.map(typeDefProp => (
              <FormControlLabel
                key={typeDefProp.type}
                control={
                  <Checkbox
                    checked={this.state[typeDefProp.type]}
                    onChange={this.handleChange(typeDefProp.type)}
                    value={typeDefProp.type}
                  />
                }
                label={this.getHeaderTitle(typeDefProp)}
              />
            ))}
          </FormGroup>
        </DialogContent>
        <DialogActions>
          <Button onClick={this.handleCancel} color='primary'>
            Cancel
          </Button>
          <Button onClick={this.handleOk} color='primary'>
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    )
  }
}

ConfirmationDialog.propTypes = {
  onClose: PropTypes.func,
  value: PropTypes.string
}

export default ConfirmationDialog
