import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import classNames from "classnames";
import Chip from "@material-ui/core/Chip";
import Avatar from "@material-ui/core/Avatar";
import Grid from "@material-ui/core/Grid";
import Popover from "@material-ui/core/Popover";

const styles = theme => ({
  iconButtonLabel: {
    display: "block"
  },
  chip: {
    height: "20px"
  },
  avatar: {
    height: "20px",
    width: "20px",
    borderRadius: "50%"
  },
  redChip: {
    backgroundColor: "#f5cfcd"
  },
  redAvatar: {
    boxSizing: "border-box",
    border: "2px solid #D94E47",
    backgroundColor: "#f5cfcd"
  },
  redAvatarSelected: {
    backgroundColor: "#D94E47"
  },
  blueChip: {
    backgroundColor: "#d3e5f3"
  },
  blueAvatar: {
    boxSizing: "border-box",
    border: "2px solid #106EBE",
    backgroundColor: "#d3e5f3"
  },
  blueAvatarSelected: {
    backgroundColor: "#106EBE"
  },
  greenChip: {
    backgroundColor: "#eeeeee"
  },
  greenAvatar: {
    boxSizing: "border-box",
    border: "2px solid #BAD252",
    backgroundColor: "#eeeeee"
  },
  greenAvatarSelected: {
    backgroundColor: "#BAD252"
  }
});

class SimplePopper extends React.Component {
  state = {
    relevant: false,
    privileged: false,
    confidential: false
  };
  constructor(props) {
    super(props);
    this.state = {
      relevant: props.relevant,
      privileged: props.privileged,
      confidential: props.confidential
    };
  }

  render() {
    const { classes } = this.props;
    const { anchorEl, open, handleClose } = this.props;

    return (
      <Popover
        id="simple-popper"
        open={open}
        anchorEl={anchorEl}
        onClose={() => handleClose(this.state)}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center"
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left"
        }}
      >
        <div style={{ padding: "4px" }}>
          <Grid container spacing={8}>
            <Grid item xs={4}>
              <Chip
                onClick={() =>
                  this.setState({ relevant: !this.state.relevant })
                }
                className={classNames(classes.chip, classes.redChip)}
                avatar={
                  <Avatar
                    className={classNames(classes.avatar, {
                      [classes.redAvatarSelected]: this.state.relevant,
                      [classes.redAvatar]: !this.state.relevant
                    })}
                  />
                }
                label="Relevant"
              />
            </Grid>
            <Grid item xs={4}>
              <Chip
                onClick={() =>
                  this.setState({ privileged: !this.state.privileged })
                }
                className={classNames(classes.chip, classes.blueChip)}
                avatar={
                  <Avatar
                    className={classNames(classes.avatar, {
                      [classes.blueAvatarSelected]: this.state.privileged,
                      [classes.blueAvatar]: !this.state.privileged
                    })}
                  />
                }
                label="Privelidged"
              />
            </Grid>
            <Grid item xs={4}>
              <Chip
                onClick={() =>
                  this.setState({ confidential: !this.state.confidential })
                }
                className={classNames(classes.chip, classes.greenChip)}
                avatar={
                  <Avatar
                    className={classNames(classes.avatar, {
                      [classes.greenAvatarSelected]: this.state.confidential,
                      [classes.greenAvatar]: !this.state.confidential
                    })}
                  />
                }
                label="Confidential"
              />
            </Grid>
          </Grid>
        </div>
      </Popover>
    );
  }
}

SimplePopper.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(SimplePopper);
