import React from 'react'
import { ReservedTypeNames } from '../../model/ReservedTypeNames'
import getStarIconButton from './starIconButton'
// import getTokenLink from "./tokenLink";
// import getIcon from "./commonIcon";
import getRainbow from './rainbowTags'
import getExpandRowCell from './expandRowButton'

export default ({ dataKey, label, header, rowData, classes, expandRow }) => {
  switch (dataKey) {
    case ReservedTypeNames.ENTITY_STAR:
    case ReservedTypeNames.PROJECT_STAR:
      return getStarIconButton({ dataKey, header, token: rowData.token })
    // case ReservedTypeNames.WORKITEMS_NAME:
    // case ReservedTypeNames.ENTITY_NAME:
    // case ReservedTypeNames.PROJECT_NAME:
    //   return getTokenLink({
    //     dataKey,
    //     label,
    //     header,
    //     token: rowData.token,
    //     classes
    //   });
    // case ReservedTypeNames.ICON:
    //   return getIcon({ dataKey, label, header, token: rowData.token });
    case ReservedTypeNames.ENTITY_RAINBOW:
      return getRainbow({
        dataKey,
        label,
        header,
        token: rowData.token,
        classes
      })
    case 'EXPANSION_BUTTON':
      return getExpandRowCell({
        dataKey,
        label,
        header,
        expanded: rowData.expanded,
        classes,
        expandRow
      })
    default:
      const _label = label !== 'undefined' ? label : '' // Fixes 'undefined' appearing in grid
      return getDefaultCell(_label, classes)
  }
}

export const getDefaultCell = (label, classes) => {
  return (
    // <div className="ReactVirtualized__Table__headerTruncatedText">
    <span className={classes.cellContents}>{label}</span>
    // </div>
  )
}
