export const fuseNavigationConfig = [
  {
    'id': 'papyrus-components',
    'title': 'Papyrus-UI Components',
    'type': 'group',
    'icon': 'apps',
    'children': [
      {
        'id': 'papyrus-table-component',
        'title': 'Papyrus-UI Table',
        'type': 'item',
        'icon': 'whatshot',
        'url': '/papyrus-table'
      },
      {
        'id': 'papyrus-search-quick-component',
        'title': 'Papyrus-UI Quick Search bar',
        'type': 'item',
        'icon': 'whatshot',
        'url': '/papyrus-search-quick'
      }
    ]
  },
  {
    'id': 'redwood-components',
    'title': 'Redwood-UI Components',
    'type': 'group',
    'icon': 'apps',
    'children': [
      {
        'id': 'redwood-table-component',
        'title': 'Redwood-UI Table',
        'type': 'item',
        'icon': 'whatshot',
        'url': '/redwood-table'
      }
    ]
  }
]
