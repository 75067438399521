export const PropDefValueTypes = {
  STRING: 0,
  NUMBER: 1,
  DECIMAL: 2,
  DATETIME: 3,
  FUNC: 4,
  ONE_OF: 5,
  ANY_OF: 6,
  BOOLEAN: 7,
  FILTER_REQUEST: 8,
  PAIRS: 9,
  ARBITRARY_VALUES: 10
}

export const TypeDefValueTypes = {
  STRING: 'STRING',
  NUMBER: 'NUMBER',
  DECIMAL: 'DECIMAL',
  DATETIME: 'DATETIME',
  FUNC: 'FUNC',
  ONE_OF: 'ONE_OF',
  ANY_OF: 'ANY_OF',
  BOOLEAN: 'BOOLEAN',
  FILTER_REQUEST: 'FILTER_REQUEST',
  PAIRS: 'PAIRS',
  ARBITRARY_VALUES: 'ARBITRARY_VALUES'
}

export function resolvePropValue (prop) {
  const vt = TypeDefValueTypes[prop.valueType] || prop.valueType || TypeDefValueTypes.STRING

  switch (vt) {
    case TypeDefValueTypes.STRING:
    case TypeDefValueTypes.NUMBER:
    case TypeDefValueTypes.DECIMAL:
    case TypeDefValueTypes.BOOLEAN:
    case TypeDefValueTypes.FILTER_REQUEST:
    case TypeDefValueTypes.PAIRS:
    case TypeDefValueTypes.ARBITRARY_VALUES:
      return prop[prop.value]
    case TypeDefValueTypes.DATETIME:
      const d = new Date(+prop.value3)
      if (d.getFullYear() < 1975) {
        // this is a temporary hack to account for dates missing millis upstream
        return new Date(+prop.value3 * 1000)
      }
      return d
    case TypeDefValueTypes.FUNC:
      /* eslint no-new-func: "off" */ // Temp
      const f = new Function('self', prop.value4)
      prop.memo = f
      return f(prop)
    case TypeDefValueTypes.ONE_OF:
      return prop.value5 && prop.value5.nestedPropIndex && prop.value5.nestedPropIndex.length
        ? resolvePropValue(prop.value5.nestedPropIndex.reduce((a, v) => a.props[v], prop))
        : null
    case TypeDefValueTypes.ANY_OF:
      return prop.value6.map(idx => resolvePropValue(prop.props[idx]))
    default:
  }
}

export function resolvePropToken (prop, type) {
  return prop.type === type ? prop.token : ((prop.props.find(p => p.type === type) || prop).token)
}

export function resolveProp (prop, type) {
  return prop.type === type ? prop : (prop.props.find(p => p.type === type) || prop)
}

export function firstAlias (prop) {
  return (
    (prop.queryAliases && prop.queryAliases.en && prop.queryAliases.en.tokens &&
            prop.queryAliases.en.tokens.length && prop.queryAliases.en.tokens) ||
        [ (prop.type || '').toLowerCase() ]
  )[0]
}
