import axios from 'axios'
import jwtDecode from 'jwt-decode'
import FuseUtils from 'modules/@fuse/FuseUtils'
// import queryString from 'query-string'

class jwtService extends FuseUtils.EventEmitter {
  init () {
    this.setInterceptors()
    this.handleAuthentication()
  }

  setInterceptors = () => {
    axios.interceptors.response.use(response => {
      return response
    }, err => {
      return new Promise((resolve, reject) => {
        if ( err.response.status === 401 && err.config && !err.config.__isRetryRequest ) {
          // if you ever get an unauthorized response, logout the user
          this.emit('onAutoLogout', 'Invalid access_token')
          this.setSession(null)
        }
        throw err
      })
    })
  }

  handleAuthentication = () => {
    let access_token = this.getAccessToken()

    if ( !access_token ) {
      // Check if we have been given a token via url
      // const urlToken = this.getUrlAccessToken()
      // if (!urlToken) {
        return
      // }
      // access_token = urlToken
    }

    if ( this.isAuthTokenValid(access_token) ) {
      this.setSession(access_token)
      this.emit('onAutoLogin', true)
    } else {
      this.setSession(null)
      this.emit('onAutoLogout', 'access_token expired')
      // TODO - Update actual login redirect to use anexsys.auth site
      // ? https://anexsys.auth.eu-west-2.amazoncognito.com/login?response_type=code&redirect_uri=https%3A%2F%2Fauth.anexsys.net%2Fcognito&state=---1546967915993
      // setTimeout(() => {
      //   // window.location = `https://anexsys.auth.eu-west-2.amazoncognito.com/login?response_type=code&redirect_uri=http%3A%2F%2Flocalhost%3A3000%2Fexample&client_id=h3k567d72qj8at8ugr36lko0m`
      //   window.location = 'https://anexsys.auth.eu-west-2.amazoncognito.com/login?response_type=code&redirect_uri=https%3A%2F%2Fauth.anexsys.net%2Fcognito&state=---1546967915993'
      // }, 1000)
    }
  }

  createUser = (data) => {
    return new Promise((resolve, reject) => {
      axios.post('/api/auth/register', data)
        .then(response => {
          if ( response.data.user ) {
            this.setSession(response.data.access_token)
            resolve(response.data.user)
          } else {
            reject(response.data.error)
          }
        })
    })
  }

  signInWithEmailAndPassword = (email, password) => {
    return new Promise((resolve, reject) => {
      axios.get('/api/auth', {
        data: {
          email,
          password
        }
      }).then(response => {
        if ( response.data.user ) {
          this.setSession(response.data.access_token)
          resolve(response.data.user)
        } else {
          reject(response.data.error)
        }
      })
    })
  }

  signInWithToken = () => {
    return new Promise((resolve, reject) => {
      const jwt = this.getAccessToken()
      const decoded = jwtDecode(jwt)
      const validJWT = decoded.isAnexsysUser && typeof decoded.anexsysId === 'string'
      if (validJWT) {
        const user = {
          role: 'staff',
          data: {
            settings: {}
          }
        }
        
        Object.assign(user.data, decoded)
        this.setSession(jwt)
        resolve(user)
      } else {
        reject('Invalid JWT supplied')
      }

      // axios.get('/api/auth/access-token', {
      //   data: {
      //     access_token: this.getAccessToken()
      //   }
      // }).then(response => {
      //   if ( response.data.user ) {
      //     this.setSession(response.data.access_token)
      //     resolve(response.data.user)
      //   } else {
      //     reject(response.data.error)
      //   }
      // })
    })
  }

  updateUserData = (user) => {
    return axios.post('/api/auth/user/update', {
      user: user
    })
  }

  setSession = access_token => {
    if ( access_token ) {
      localStorage.setItem('jwt_access_token', access_token)
      axios.defaults.headers.common['Authorization'] = 'Bearer ' + access_token
    } else {
      localStorage.removeItem('jwt_access_token')
      delete axios.defaults.headers.common['Authorization']
    }
  }

  logout = () => {
    this.setSession(null)
  }

  isAuthTokenValid = access_token => {
    if ( !access_token ) {
      return false
    }
    try {
      const decoded = jwtDecode(access_token)
      const currentTime = Date.now() / 1000
      if ( decoded.exp < currentTime ) {
        console.warn('access token expired')
        return false
      } else {
        return true
      }
    } catch (err) {
      const msg = `Invalid AccessToken supplied: ${err.message}`
      console.error(msg)
      return false
    }
  }

  getAccessToken = () => {
    return window.localStorage.getItem('jwt_access_token')
  }

  // getUrlAccessToken = () => {
  //   const url = window.location
  //   const query = queryString.parse(url.search)
  //   if (query && query.token) {
  //     return query.token
  //   }
  // }
}

const instance = new jwtService()

export default instance
