import React, { Component } from 'react'
import PropTypes from 'prop-types'
import MultiGrid from 'react-virtualized/dist/commonjs/MultiGrid'
import classNames from 'classnames'
import Draggable from 'react-draggable'
import { withStyles } from '@material-ui/core/styles'
import Table from '@material-ui/core/Table'
// import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableFooter from '@material-ui/core/TableFooter'
import TablePagination from '@material-ui/core/TablePagination'
import TableSortLabel from '@material-ui/core/TableSortLabel'
import { calcColumnWidth } from './utils'
import getCell from './cellComponents'
import { ReservedTypeNames } from '../model/ReservedTypeNames'
import defaultCellRangeRenderer from './defaultCellRangeRenderer'

const FOOTER_BORDER_HEIGHT = 1

function cellRangeRenderer (props) {
  const children = defaultCellRangeRenderer(props)
  return children
}

export const styles = theme => ({
  table: {
    boxSizing: 'border-box',
    border: `1px solid ${theme.palette.text.lightDivider}`,

    '& .topLeftGrid': {
      backgroundColor: theme.palette.grey['200'],
      borderBottom: `2px solid ${theme.palette.divider}`,
      borderRight: `2px solid ${theme.palette.divider}`,
      color: theme.palette.text.secondary,
      fontSize: theme.typography.pxToRem(12),

      // Hide scrollbars on Chrome/Safari/IE
      '&::-webkit-scrollbar': {
        display: 'none'
      },
      '-ms-overflow-style': 'none'
    },

    '& .topRightGrid': {
      backgroundColor: theme.palette.grey['200'],
      borderBottom: `2px solid ${theme.palette.divider}`,
      color: theme.palette.text.secondary,
      fontSize: theme.typography.pxToRem(12),

      // Hide scrollbars on Chrome/Safari/IE
      '&::-webkit-scrollbar': {
        display: 'none'
      },
      '-ms-overflow-style': 'none'
    },

    '& .bottomLeftGrid': {
      backgroundColor: theme.palette.grey['200'],
      borderRight: `2px solid ${theme.palette.divider}`,
      color: theme.palette.text.secondary,
      fontSize: theme.typography.pxToRem(13),

      // Hide scrollbars on Chrome/Safari/IE
      '&::-webkit-scrollbar': {
        display: 'none'
      },
      '-ms-overflow-style': 'none'
    },

    '& .bottomRightGrid': {
      color: theme.palette.text.primary,
      fontSize: theme.typography.pxToRem(13),
      outline: 'none', // See: https://github.com/bvaughn/react-virtualized/issues/381
      overflowX: 'hidden !important'
    }
  },
  cell: {
    boxSizing: 'border-box',
    display: 'flex',
    alignItems: 'center',
    paddingLeft: '8px',
    paddingRight: '0px',
    // borderRight: `1px solid ${theme.palette.text.lightDivider}`,
    '&:last-child': {
      paddingRight: '0'
    }
  },
  noPaddingCell: {
    paddingLeft: '0',
    paddingRight: '0'
  },
  cellSelected: {
    backgroundColor: theme.palette.grey[100]
  },
  cellHovered: {
    backgroundColor: theme.palette.grey[200]
  },
  cellContents: {
    width: '100%',
    height: '100%',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  },
  cellHeader: {
    fontSize: theme.typography.pxToRem(12),
    fontWeight: theme.typography.fontWeightMedium,
    color: theme.palette.text.secondary
  },
  cellInLastColumn: {
    paddingRight: 0
  },
  cellInLastRow: {
    borderBottom: 'none'
  },
  footer: {
    borderTop: `${FOOTER_BORDER_HEIGHT}px solid ${theme.palette.divider}`
  },
  dragHandle: {
    flex: '0 0 16px',
    zIndex: 2,
    cursor: 'col-resize',
    color: '#0085ff'
  },
  DragHandleActive: {
    color: '#0b6fcc',
    zIndex: 3
  },
  DragHandleIcon: {
    flex: '0 0 12px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center'
  }
})

class MuiTable extends Component {
  constructor (props) {
    super(props)
    var widths = {}
    this.state = {
      hoveredColumn: null,
      hoveredRowData: null,
      widths
    }
    this.rowHeight = this.rowHeight.bind(this)
  }

  componentWillReceiveProps (nextProps) {
    if (
      nextProps.width !== this.props.width ||
      nextProps.columns !== this.props.columns
    ) {
      var widths = {}
      var initialWidth = 1
      var columns = []
      nextProps.columns.forEach(c => {
        if (c && c.width) {
          widths[c.name] = c.width / nextProps.width
          initialWidth = initialWidth - c.width / nextProps.width
        } else {
          columns.push(c)
        }
      })
      columns.forEach(c => {
        widths[c.name] = initialWidth / columns.length
      })
      // if (Object.keys(this.state.widths).length === 0) {
      //   columns.forEach(c => {
      //     widths[c.name] = initialWidth / columns.length;
      //   });
      // } else {
      //   var newWidths = columns
      //     .map(c => [
      //       c.name,
      //       (this.state.widths[c.name] * initialWidth) / columns.length
      //     ])
      //     .reduce(function(prev, curr) {
      //       prev[curr[0]] = curr[1];
      //       return prev;
      //     }, {});

      //   //widths = Object.assign({}, newWidths, widths);
      //   widths = Object.assign({}, this.state.widths, widths);
      // }
      this.setState({
        widths
      })

      this.multiGrid.recomputeGridSize()
    }
  }

  resizeRow = ({ dataKey, deltaX }) =>
    this.setState(
      prevState => {
        const prevWidths = prevState.widths
        const percentDelta = deltaX / this.props.width
        const columns = this.props.columns
        const index = columns.findIndex(c => c.name === dataKey)
        const nextDataKey = columns[index + 1].name
        const newWidths = {
          [dataKey]: prevWidths[dataKey] + percentDelta,
          [nextDataKey]: prevWidths[nextDataKey] - percentDelta
        }
        var widths = {
          ...prevWidths,
          ...newWidths
        }
        if (Object.values(newWidths).some(w => w < 0.05)) {
          return {}
        }
        const ratio = Object.values(widths).reduce(
          (sum, value) => sum + value,
          0
        )
        if (ratio <= 1) {
          return { widths }
        }
        return {}
      },
      () => {
        this.multiGrid.recomputeGridSize()
      }
    );

  resizableColumnWidths (index, columns, tableWidth) {
    const column = columns[index]
    return this.state.widths[column.name] * this.props.width
  }

  rowHeight (params) {
    const { data } = this.props
    const item = data[params.index - 1]
    if (item && item.expanded) {
      return 200
    } else {
      return 48
    }
  }

  cellRenderer = ({ columnIndex, rowIndex, key, style }) => {
    const {
      data,
      columns,
      includeHeaders,
      isCellHovered,
      isCellSelected,
      classes,
      orderBy,
      orderDirection,
      onHeaderClick,
      onCellClick,
      resizable,
      // expandedRow,
      highlight,
      cellProps: defaultCellProps
    } = this.props

    const { hoveredColumn, hoveredRowData } = this.state

    const column = columns[columnIndex]
    const isHeader = includeHeaders && rowIndex === 0
    const headerOffset = includeHeaders ? 1 : 0
    const rowData = (data && data[rowIndex - headerOffset]) || {}

    const isSelected = isCellSelected && isCellSelected(column, rowData)

    const isHovered =
      hoveredColumn &&
      hoveredRowData &&
      isCellHovered &&
      isCellHovered(column, rowData, hoveredColumn, hoveredRowData)
    const resolveCellProps = cellProps =>
      typeof cellProps === 'function'
        ? cellProps(column, rowData, hoveredColumn, hoveredRowData)
        : cellProps
    // TODO: Deep merge (do not override all defaultCellProps styles if column.cellProps.styles defined?)
    const { style: cellStyle, ...cellProps } = {
      ...resolveCellProps(defaultCellProps),
      ...resolveCellProps(column.cellProps)
    }

    const contents = isHeader
      ? column.header != null
        ? column.header
        : column.name
      : column.cell
        ? column.cell(rowData)
        : rowData[column.name]

    const className = classNames(classes.cell, {
      [classes.cellHovered]: isHovered,
      [classes.cellSelected]: isSelected,
      [classes.cellHeader]: isHeader,
      [classes.cellInLastColumn]: columnIndex === columns.length - 1,
      [classes.cellInLastRow]: rowIndex === (data ? data.length : 0),
      [classes.noPaddingCell]:
        column.name === ReservedTypeNames.PROJECT_STAR ||
        column.name === ReservedTypeNames.ENTITY_STAR ||
        column.name === 'EXPANSION_BUTTON' ||
        column.name === ReservedTypeNames.ICON ||
        column.name === 'checkbox'
    })

    const hasCellClick = !isHeader && onCellClick
    // const hasCellExpandClick = !isHeader && expandedRow

    if (highlight && highlight === rowData.token) {
      style.backgroundColor = 'lightblue'
    }
    return (
      <TableCell
        component='div'
        className={className}
        key={key}
        onMouseEnter={() => {
          this.setState({ hoveredColumn: column, hoveredRowData: rowData })
        }}
        onMouseLeave={() =>
          this.setState({ hoveredColumn: null, hoveredRowData: null })
        }
        style={{
          ...style,
          ...cellStyle,
          ...((hasCellClick || column.onClick) && { cursor: 'pointer' })
        }}
        {...hasCellClick && {
          onClick: () => onCellClick(column, rowData)
        }} // Can be overridden by cellProps.onClick on column definition
        {...cellProps}
      >
        {column.name !== ReservedTypeNames.PROJECT_STAR &&
        column.name !== ReservedTypeNames.ENTITY_STAR &&
        column.name !== 'EXPANSION_BUTTON' &&
        column.name !== 'checkbox' &&
        isHeader &&
        column.onHeaderClick !== false &&
        (column.onHeaderClick || onHeaderClick) ? (
            <React.Fragment>
              <TableSortLabel
                active={
                  !!(orderBy && orderBy === column.name && rowIndex === 0)
                }
                style={{ width: 'inherit' }} // fix text overflowing
                direction={orderDirection}
                onClick={() =>
                  column.onHeaderClick
                    ? column.onHeaderClick()
                    : onHeaderClick(column)
                }
              >
                {column.name === ReservedTypeNames.PROJECT_STAR ||
                column.name === ReservedTypeNames.ENTITY_STAR ||
                column.name === 'EXPANSION_BUTTON'
                  ? ''
                  : contents}
              </TableSortLabel>
              {column.name !== ReservedTypeNames.ICON &&
              column.name !== 'checkbox' &&
              column.name !== 'EXPANSION_BUTTON' &&
              resizable &&
              (columns.some(c => c.name === 'EXPANSION_BUTTON')
                ? columnIndex < columns.length - 2
                : columnIndex < columns.length - 1) && (
                <span style={{ float: 'right' }}>
                  <Draggable
                    axis='x'
                    defaultClassName={classes.dragHandle}
                    defaultClassNameDragging={classes.DragHandleActive}
                    onDrag={(event, { deltaX }) =>
                      this.resizeRow({
                        dataKey: column.name,
                        deltaX
                      })
                    }
                    position={{ x: 0 }}
                    zIndex={999}
                  >
                    <span className={classes.DragHandleIcon}>⋮</span>
                  </Draggable>
                </span>
              )}
            </React.Fragment>
          ) : (
            <div
              className={classes.cellContents}
              style={
                (column.name !== ReservedTypeNames.PROJECT_STAR &&
                column.name !== ReservedTypeNames.ENTITY_STAR &&
                column.name !== ReservedTypeNames.ICON &&
                column.name !== 'checkbox' &&
                column.name !== 'EXPANSION_BUTTON') ||
              (isHeader && column.name === ReservedTypeNames.ICON)
                  ? { paddingTop: '11px' }
                  : {}
              }
            >
              <span style={{ flex: 'auto' }}>
                {getCell({
                  dataKey: column.name,
                  label: contents,
                  header: isHeader,
                  token: rowData.token,
                  rowData,
                  classes,
                  expandRow: () => {
                    rowData.expanded = !rowData.expanded
                    this.multiGrid.recomputeGridSize(0, rowIndex)
                  }
                })}
              </span>
              {column.name !== ReservedTypeNames.PROJECT_STAR &&
              column.name !== ReservedTypeNames.ENTITY_STAR &&
              column.name !== ReservedTypeNames.ICON &&
              column.name !== 'checkbox' &&
              isHeader &&
              resizable &&
              columnIndex < columns.length - 2 && (
                <span style={{ float: 'right' }}>
                  <Draggable
                    axis='x'
                    defaultClassName={classes.dragHandle}
                    defaultClassNameDragging={classes.DragHandleActive}
                    onDrag={(event, { deltaX }) =>
                      this.resizeRow({
                        dataKey: column.name,
                        deltaX
                      })
                    }
                    position={{ x: 0 }}
                    zIndex={999}
                  >
                    <span className={classes.DragHandleIcon}>⋮</span>
                  </Draggable>
                </span>
              )}
            </div>
          )}
      </TableCell>
    )
  };

  render () {
    const {
      data,
      columns,
      width,
      height,
      maxHeight,
      pagination,
      fitHeightToRows,
      fixedRowCount,
      fixedColumnCount,
      rowHeight,
      columnWidth,
      includeHeaders,
      classes,
      orderBy,
      orderDirection,
      onHeaderClick,
      onCellClick,
      isCellHovered,
      isCellSelected,
      cellProps,
      style,
      theme,
      resizable,
      expandedRow,
      entityDetailsSelector,
      fetchEntityDetails,
      ...props
    } = this.props

    let calculatedHeight = 0
    if (height) {
      calculatedHeight = height // fixed height
    } else if (pagination && pagination.rowsPerPage && !fitHeightToRows) {
      const rowCount =
        pagination.rowsPerPage +
        (fixedRowCount || (includeHeaders ? 1 : 0))
      calculatedHeight = rowCount * rowHeight
    } else if (Array.isArray(data)) {
      const rowCount =
        data.length + (fixedRowCount || (includeHeaders ? 1 : 0))
      calculatedHeight = rowCount * rowHeight
    }

    const paginationHeight = 1
    // theme.mixins.toolbar.minHeight + FOOTER_BORDER_HEIGHT;

    const calculatedHeightWithFooter =
      calculatedHeight + (pagination ? paginationHeight : 0)
    const containerHeight =
      maxHeight != null
        ? Math.min(calculatedHeightWithFooter, maxHeight)
        : calculatedHeightWithFooter
    const multiGridHeight =
      containerHeight - (pagination ? paginationHeight : 0)

    return (
      <Table
        component='div'
        style={{ width, height: containerHeight, ...style }}
        className={classes.table}
        {...props}
      >
        <MultiGrid
          cellRangeRenderer={props =>
            cellRangeRenderer({
              ...props,
              data,
              expandedRow,
              entityDetailsSelector,
              fetchEntityDetails
            })
          }
          cellRenderer={this.cellRenderer}
          ref={el => (this.multiGrid = el)}
          width={width}
          columnWidth={
            columnWidth || resizable
              ? ({ index }) => this.resizableColumnWidths(index, columns, width)
              : ({ index }) => calcColumnWidth(index, columns, width)
          }
          columnCount={Array.isArray(columns) ? columns.length : 0}
          fixedColumnCount={fixedColumnCount}
          enableFixedColumnScroll={fixedColumnCount > 0}
          height={multiGridHeight}
          estimatedRowSize={48}
          rowHeight={expandedRow ? this.rowHeight : rowHeight}
          rowCount={
            Array.isArray(data) ? data.length + (includeHeaders ? 1 : 0) : 0
          }
          fixedRowCount={fixedRowCount}
          enableFixedRowScroll={fixedRowCount > 0}
          // TODO: Read tehse from `classes` without classes.table inherirtance?  How to pass props.classes down to override?
          classNameTopLeftGrid={'topLeftGrid'}
          classNameTopRightGrid={'topRightGrid'}
          classNameBottomLeftGrid={'bottomLeftGrid'}
          classNameBottomRightGrid={'bottomRightGrid'}
        />

        {pagination && (
          <TableFooter component='div' className={classes.footer}>
            <TablePagination component='div' {...pagination} />
          </TableFooter>
        )}
      </Table>
    )
  }
}

MuiTable.propTypes = {
  data: PropTypes.array,
  columns: PropTypes.array,
  width: PropTypes.number.isRequired,
  height: PropTypes.number,
  maxHeight: PropTypes.number,
  pagination: PropTypes.object,
  fitHeightToRows: PropTypes.bool,
  fixedRowCount: PropTypes.number,
  fixedColumnCount: PropTypes.number,
  rowHeight: PropTypes.number,
  columnWidth: PropTypes.number,
  includeHeaders: PropTypes.bool,
  orderBy: PropTypes.string,
  orderDirection: PropTypes.string,
  onHeaderClick: PropTypes.func,
  onCellClick: PropTypes.func,
  isCellHovered: PropTypes.func,
  isCellSelected: PropTypes.func,
  classes: PropTypes.object,
  cellProps: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
  style: PropTypes.object
}

MuiTable.defaultProps = {
  rowHeight: 48,
  maxHeight: null,
  includeHeaders: false,
  fixedRowCount: 0,
  fixedColumnCount: 0
}
// export default MuiTable;
export default withStyles(styles)(MuiTable)

// export default withStyles(styles, { withTheme: true })(MuiTable);
