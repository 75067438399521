import { getTOGGLE_SELECTED_HEADER, getTOGGLE_SELECTED } from "./actionTypes";

export function getSelectionActions(panelIdentifier) {
  const TOGGLE_SELECTED_HEADER = getTOGGLE_SELECTED_HEADER(panelIdentifier);
  const TOGGLE_SELECTED = getTOGGLE_SELECTED(panelIdentifier);

  return {
    toggleSelected(token) {
      return {
        type: TOGGLE_SELECTED,
        token
      };
    },
    toggleSelectedHeader(data) {
      return {
        type: TOGGLE_SELECTED_HEADER,
        data
      };
    }
  };
}
