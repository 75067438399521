import TableComponent from "./tableIndex";
import { getTableActions, getTableReducers } from "./store";
import SettingsCog from "./components/headerSettingsCog";

export { TableComponent };

export default {
  TableComponent,
  getTableActions,
  getTableReducers,
  SettingsCog
};
