import React from "react";
// import projectStore from "../../../projectPanel/store";
// import entityStore from "../../../entityPanel/store";
import { ReservedTypeNames } from "../../../model/ReservedTypeNames";
import StarIconButton from "./StarIconButton";

const getStarIconButton = props => {
  if (props.header) {
    return props.label;
  } else {
    return getRowStar(props);
  }
};

const getRowStar = ({ dataKey, token }) => {
  switch (dataKey) {
    case ReservedTypeNames.ENTITY_STAR:
      return (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "-8px"
          }}
        >
          <StarIconButton
            token={token}
            // toggleStarAction={entityStore.actions.toggleEntityStar}
            // starredSelect={entityStore.selectors.selectEntityStar}
          />
        </div>
      );
    case ReservedTypeNames.PROJECT_STAR:
      return (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "-8px"
          }}
        >
          <StarIconButton
            token={token}
            // toggleStarAction={projectStore.actions.toggleProjectStar}
            // starredSelect={projectStore.selectors.selectProjectStar}
          />
        </div>
      );
    default:
      return;
  }
};

export default getStarIconButton;
