export const ReservedTypeNames = {
    ID: 'ID',
    MUTABLE: 'MUTABLE',
    LAYOUT_KEY_ENTITY_TAGGING: 'LAYOUT_KEY_ENTITY_TAGGING',
    LAYOUT_KEY_PROJECT_ENTITIES: 'LAYOUT_KEY_PROJECT_ENTITIES',
    //--------PROJECT_SUMMARY--------
    PROJECT_SUMMARY: 'PROJECT_SUMMARY',
    PROJECT_STAR: 'PROJECT_STAR',
    PROJECT_NAME: 'PROJECT_NAME',
    PROJECT_CLIENT: 'PROJECT_CLIENT',
    PROJECT_DATE: 'PROJECT_DATE',
    PROJECT_OWNER: 'PROJECT_OWNER',
    PROJECT_SIZE: 'PROJECT_SIZE',
    //--------PROJECT_ENTITY_SUMMARY--------
    PROJECT_ENTITY_SUMMARY: 'PROJECT_ENTITY_SUMMARY',
    PROJECT_ENTITY_DETAILS: 'PROJECT_ENTITY_DETAILS',
    ENTITY_STAR: 'ENTITY_STAR',
    ENTITY_NAME: 'ENTITY_NAME',
    ENTITY_RAINBOW: 'ENTITY_RAINBOW',
    //--------PROJECT_ENTITIES_WORKITEMS--------
    PROJECT_ENTITIES_WORKITEMS: 'PROJECT_ENTITIES_WORKITEMS',
    WORKITEMS_NAME: 'WORKITEMS_NAME',
    PROJECT_ENTITY_PSPDFKIT: 'PROJECT_ENTITY_PSPDFKIT',
    PATH_TREE_ENTRY: 'PATH_TREE_ENTRY',
    ENTITY_ID: 'ENTITY_ID',
    ICON: 'ICON',
    //--------PROJECT_ENTITY_SUMMARY_FOR_SUGGESTION_LIST--------
    PROJECT_ENTITY_SUMMARY_FOR_SUGGESTION_LIST: 'PROJECT_ENTITY_SUMMARY_FOR_SUGGESTION_LIST',
};