import { TypeDefValueTypes } from "../model/ValueTypes";

const compareBool = (a, b) => {
  if (!a && !b) {
    return 0;
  }
  if (a) {
    return 1;
  }
  if (b) {
    return -1;
  }
};
const compareString = (a, b) => {
  if ((a === null || a === undefined) && (b === null || b === undefined)) {
    return 0;
  }
  if (a === null || a === undefined) {
    return -1;
  }
  if (b === null || b === undefined) {
    return 1;
  }
  return a.toString().localeCompare(b.toString());
};
const compareNumber = (a, b) => {
  if (!isValidNumber(a) && !isValidNumber(b)) {
    return 0;
  }
  if (isValidNumber(a) && !isValidNumber(b)) {
    return 1;
  }
  if (!isValidNumber(a) && isValidNumber(b)) {
    return -1;
  }
  const numberA = Number(a);
  const numberB = Number(b);
  return numberA - numberB;
};

const compareDate = (a, b) => {
  const dateA = new Date(a);
  const dateB = new Date(b);
  if (isNaN(dateA.getTime()) && isNaN(dateA.getTime())) {
    return 0;
  }
  if (!isNaN(dateA.getTime()) && isNaN(dateA.getTime())) {
    return 1;
  }
  if (isNaN(dateA.getTime()) && !isNaN(dateA.getTime())) {
    return -1;
  }

  return dateA.getTime() - dateB.getTime();
};

const isValidNumber = value => {
  if (value === null || value === undefined) {
    return false;
  }
  const valueNumber = Number(value);
  return !Number.isNaN(valueNumber);
};

const defaultTypeComparatorsMap = {};
defaultTypeComparatorsMap[TypeDefValueTypes.STRING] = compareString;
defaultTypeComparatorsMap[TypeDefValueTypes.NUMBER] = compareNumber;
defaultTypeComparatorsMap[TypeDefValueTypes.DECIMAL] = compareNumber;
defaultTypeComparatorsMap[TypeDefValueTypes.DATETIME] = compareDate;
defaultTypeComparatorsMap[TypeDefValueTypes.BOOLEAN] = compareBool;

const sort = (rows, columns, sorting) => {
  if (
    !rows ||
    rows.length === 0 ||
    !sorting ||
    sorting.length !== 1 ||
    !columns ||
    columns.length === 0
  ) {
    return rows;
  }
  // const sortColumnIndex =
  //   columns.findIndex(col => col.name === sorting[0].columnName);

  var columnName = sorting[0].columnName;
  if (columnName === "CLIENT") {
    columnName = "PROJECT_CLIENT";
  } else if (columnName === "DATE") {
    columnName = "PROJECT_DATE";
  } else if (columnName === "OWNER") {
    columnName = "PROJECT_OWNER";
  } else if (columnName === "SIZE") {
    columnName = "PROJECT_SIZE";
  } else if (columnName === "ID") {
    columnName = "ENTITY_ID";
  }
  const sortColumnIndex = rows[0].props.findIndex(
    prop => prop.type === columnName
  );

  if (sortColumnIndex < 0) {
    return rows;
  }
  //let cmpFn = defaultTypeComparatorsMap[columns[sortColumnIndex].valueType];
  let cmpFn =
    defaultTypeComparatorsMap[rows[0].props[sortColumnIndex].valueType];
  if (!cmpFn) {
    cmpFn = defaultTypeComparatorsMap[TypeDefValueTypes.STRING];
  }
  const newRows = rows.map(item => Object.assign({}, item));
  return newRows.sort((a, b) => {
    return (
      cmpFn(
        a.props[sortColumnIndex][a.props[sortColumnIndex].value],
        b.props[sortColumnIndex][b.props[sortColumnIndex].value]
      ) * (sorting[0].direction === "desc" ? -1 : 1)
    );
  });
};

export default sort;
