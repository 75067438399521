import React from "react";
import { withStyles } from "@material-ui/core/styles";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import ListIcon from "@material-ui/icons/List";
import EditIcon from "@material-ui/icons/Edit";
import DetailedListIcon from "@material-ui/icons/ViewList";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import { connect } from "react-redux";
import IconButton from "@material-ui/core/IconButton";
import Settings from "@material-ui/icons/Settings";
import Divider from "@material-ui/core/Divider";
import ColumnChooserDialog from "./ColumnChooserDialog";
import { getTableActions } from "../../store/actions";
import PropTypes from "prop-types";
const styles = theme => ({
  icon: {
    height: 30,
    width: 30
  },
  paper: {
    width: "80%",
    maxHeight: 435
  }
});

class SettingsCog extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      settingsOpened: false,
      columnDialogOpen: false
    };
  }

  closeSettings() {
    this.setState({ settingsOpened: false });
  }

  openSettings(event) {
    this.setState({ settingsOpened: true, anchorEl: event.currentTarget });
  }

  handleClose(columns) {
    this.props.setHiddenColumns(columns);
    this.setState({ columnDialogOpen: false });
  }
  handleCancel() {
    this.setState({ columnDialogOpen: false });
  }

  render() {
    const { classes, typeDefinition, columnsToHide } = this.props;
    const { setListLayout, setDetailedListLayout } = this.props;
    return (
      <React.Fragment>
        <IconButton className={classes.icon} onClick={this.openSettings}>
          <Settings style={{ color: "gray" }} />
        </IconButton>
        <Menu
          anchorEl={this.state.anchorEl}
          open={this.state.settingsOpened}
          onClose={this.closeSettings}
        >
          {setListLayout && (
            <MenuItem
              onClick={() => {
                setListLayout();
                this.closeSettings();
              }}
            >
              <ListItemIcon>
                <ListIcon />
              </ListItemIcon>
              <ListItemText inset primary="List" />
            </MenuItem>
          )}
          {setDetailedListLayout && (
            <MenuItem
              onClick={() => {
                setDetailedListLayout();
                this.closeSettings();
              }}
            >
              <ListItemIcon>
                <DetailedListIcon />
              </ListItemIcon>
              <ListItemText inset primary="Detailed List" />
            </MenuItem>
          )}
          {(setListLayout || setDetailedListLayout) && <Divider />}

          <MenuItem
            onClick={() => {
              this.setState({ columnDialogOpen: true });
              this.closeSettings();
            }}
          >
            <ListItemIcon>
              <EditIcon />
            </ListItemIcon>
            <ListItemText inset primary="Change Columns" />
          </MenuItem>
        </Menu>
        <ColumnChooserDialog
          key={columnsToHide}
          classes={{
            paper: classes.paper
          }}
          open={this.state.columnDialogOpen}
          onClose={this.handleClose}
          onCancel={this.handleCancel}
          columnsToHide={columnsToHide}
          typeDefinition={typeDefinition}
        />
      </React.Fragment>
    );
  }
}

SettingsCog.propTypes = {
  panelIdentifier: PropTypes.string.isRequired,
  setListLayout: PropTypes.func,
  setDetailedListLayout: PropTypes.func,
  setIconViewLayout: PropTypes.func,
  selectColumnsToHide: PropTypes.func.isRequired,
  typeDefinition: PropTypes.object.isRequired
};

const mapStateToProps = (state, ownProps) => {
  return {
    columnsToHide: ownProps.selectColumnsToHide(state)
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  const tableActions = getTableActions(ownProps.panelIdentifier);
  return {
    setHiddenColumns: columns => {
      dispatch(tableActions.setHiddenColumns(columns));
    },
    setListLayout: ownProps.setListLayout
      ? () => {
          dispatch(ownProps.setListLayout());
        }
      : undefined,
    setDetailedListLayout: ownProps.setDetailedListLayout
      ? () => {
          dispatch(ownProps.setDetailedListLayout());
        }
      : undefined,
    setIconViewLayout: () => {
      dispatch(ownProps.setIconViewLayout());
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(SettingsCog));
