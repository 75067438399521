import * as React from "react";
import {CircularProgress, withStyles} from "@material-ui/core";

const styles = {
    root: {
        width: '100%',
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    }
};

const Loader = (props) => (
    <div className={props.classes.root} style={props.innerStyle}>
        <CircularProgress/>
    </div>
);

Loader.defaultProps = {
    innerStyle: {}
};

export default withStyles(styles)(Loader);
