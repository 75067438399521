import { getSET_HIDDEN_COLUMNS } from "./actionTypes";

export function gethiddenColumnsReducer(panelIdentifier) {
  const SET_HIDDEN_COLUMNS = getSET_HIDDEN_COLUMNS(panelIdentifier);

  function hiddenColumnsReducer(state = [], action) {
    switch (action.type) {
      case SET_HIDDEN_COLUMNS:
        return action.columns;
      default:
        return state;
    }
  }

  return hiddenColumnsReducer;
}
