import React, { Component } from "react";
import { IconButton, withStyles } from "@material-ui/core";
import ExpandMore from "@material-ui/icons/ExpandMore";
import classnames from "classnames";

const styles = theme => ({
  expand: {
    width     : "40px",
    height    : "40px",
    transform : "rotate(0deg)",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest
    })
  },
  expandOpen: {
    transform: "rotate(180deg)"
  }
});

class ExpandRowButton extends Component {
  constructor(props) {
    super(props);
    this.onClick = this.onClick.bind(this);
  }

  onClick(event) {
    event.stopPropagation();
    this.props.expandRow();
  }

  render() {
    const { checked, classes } = this.props;
    return (
      <IconButton
        onClick={this.onClick}
        className={classnames(classes.expand, {
          [classes.expandOpen]: this.props.expanded
        })}
      >
        <ExpandMore
          color={checked ? "primary" : "action"}
          style={this.props.iconStyle}
        />
      </IconButton>
    );
  }
}

export default withStyles(styles)(ExpandRowButton);
