import React, { Component } from 'react'
import { Grid, Typography, withStyles } from '@material-ui/core'
import PropTypes from 'prop-types'
import Loader from '../loader/Loader'
import { connect } from 'react-redux'

const styles = theme => ({
  leftPanelContainer: {
    display: 'flex',
    flexDirection: 'column',
    wordWrap: 'break-word',
    '& > div': {
      marginBottom: '5px'
    }
  },
  rightPanelContainer: {},
  leftGrid: {
    borderRight: '1px solid black'
  },
  rightGrid: {
    paddingLeft: '10px'
  },
  mainGrid: {
    height: '152px',
    paddingTop: '10px',
    paddingBottom: '10px',
    // borderTop: '1px solid black',
    color: '#555'
  }
})

class GenericRowDetails extends Component {
  renderLeftPanel = rowDetails => {
    const { classes } = this.props
    return (
      <div className={classes.leftPanelContainer}>
        {rowDetails.values.map(kvp => (
          <Typography key={kvp.k}>
            <b>{kvp.k}:</b> {kvp.v}
          </Typography>
        ))}
      </div>
    )
  };

  renderRightPanel = rowDetails => {
    const { classes } = this.props
    return (
      <div className={classes.rightPanelContainer}>
        <Typography>{rowDetails.detailsText}</Typography>
      </div>
    )
  };

  render () {
    const { classes, rowDetailsResolver, rowDetails } = this.props
    if (!rowDetails) {
      rowDetailsResolver()
      return <Loader />
    }
    return (
      <div>
        <Grid container spacing={0} className={classes.mainGrid}>
          <Grid item xs={3} className={classes.leftGrid}>
            {this.renderLeftPanel(rowDetails)}
          </Grid>
          <Grid item xs={9} className={classes.rightGrid}>
            {this.renderRightPanel(rowDetails)}
          </Grid>
        </Grid>
      </div>
    )
  }
}

GenericRowDetails.defaultProps = {
  left: true
}

GenericRowDetails.propTypes = {
  row: PropTypes.object,
  click: PropTypes.func,
  left: PropTypes.bool
}
const mapStateToProps = (state, ownProps) => {
  return {
    rowDetails: ownProps.entityDetailsSelector(state, ownProps.token)
  }
}
const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    rowDetailsResolver: () =>
      dispatch(ownProps.rowDetailsResolver(ownProps.token))
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(GenericRowDetails))
