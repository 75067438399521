import React, { Component } from "react";
import { IconButton, withStyles } from "@material-ui/core";
import Star from "@material-ui/icons/Star";
import PropTypes from "prop-types";
import { connect } from "react-redux";

const styles = theme => ({
  iconBtn: {
    width : "32px",
    height: "32px"
  }
});

class StarIconButton extends Component {
  onClick = event => {
    event.stopPropagation();

    this.props.toggleStar();
  };

  render() {
    const { checked } = this.props;
    return (
      <IconButton
        className={this.props.classes.iconBtn}
        onClick={this.onClick}
        style={this.props.iconButtonStyle}
      >
        <Star
          color={checked ? "primary" : "action"}
          style={this.props.iconStyle}
        />
      </IconButton>
    );
  }
}

StarIconButton.defaultProps = {
  propagateClickEvent: false,
  checked            : false,
  controlledState    : true
};

StarIconButton.propTypes = {
  propagateClickEvent: PropTypes.bool,
  iconStyle          : PropTypes.object,
  starredSelect      : PropTypes.func,
  toggleStarAction   : PropTypes.func,
  token              : PropTypes.string
};

const mapStateToProps = (state, ownProps) => {
  if (ownProps.token) {
    return {
      checked: ownProps.starredSelect
        ? ownProps.starredSelect(state, ownProps.token)
        : false
    };
  } else {
    return {
      checked: ownProps.starredSelect ? ownProps.starredSelect(state) : false
    };
  }
};

const mapDispatchToProps = (dispatch, ownProps) => {
  if (ownProps.token) {
    return {
      toggleStar: () => dispatch(ownProps.toggleStarAction(ownProps.token))
    };
  } else {
    return {
      toggleStar: () => dispatch(ownProps.toggleStarAction())
    };
  }
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(StarIconButton));
