import PapyrusTable from './Table'

export const PapyrusTableConfig = {
  settings: {
    layout: {
      config: {}
    }
  },
  routes: [{
    path: '/papyrus-table',
    component: PapyrusTable
  }]
}
