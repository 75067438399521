import { getTOGGLE_SELECTED_HEADER, getTOGGLE_SELECTED } from "./actionTypes";

export function getselectionReducer(panelIdentifier) {
  const TOGGLE_SELECTED_HEADER = getTOGGLE_SELECTED_HEADER(panelIdentifier);
  const TOGGLE_SELECTED = getTOGGLE_SELECTED(panelIdentifier);

  function selectionReducer(state = { selectedRows: [] }, action) {
    switch (action.type) {
      case TOGGLE_SELECTED:
        var selectedRows = [];
        selectedRows = selectedRows.concat(state.selectedRows);
        var index = selectedRows.indexOf(action.token);
        if (index > -1) {
          selectedRows.splice(index, 1);
        } else {
          selectedRows.push(action.token);
        }
        return Object.assign({}, { selectedRows });
      case TOGGLE_SELECTED_HEADER:
        if (state.selectedRows.length === action.data.length) {
          // deselect all
          return Object.assign({}, { selectedRows: [] });
        } else {
          // select all
          return Object.assign(
            {},
            { selectedRows: action.data.map(d => d.token) }
          );
        }
      default:
        return state;
    }
  }

  return selectionReducer;
}
